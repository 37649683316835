// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  // API: 'http://surveyapp-dev-kl.knowledgelens.com/test_v3.0/',

   API: 'https://mzs.dev.zsservices.com/services/',
  REPORTAPI: 'https://mzs.dev.zsservices.com/reports/',
  // API: '/moment_zs/',
  // API: 'http://192.168.1.65:50089/',
  // API: 'http://localhost:50049/',
  // API: 'http://server.knowledgelens.com/test_v4.0/',
  // API: '/mzapi/',
  // API: 'http://momentzs-dev.knowledgelens.com/test_v4.0/',
  // API: 'http://951d55ee6188.ngrok.io/',
  // API: 'https://surveyapp.knowledgelens.com/momentZS_v2.0/',
  // API: 'http://192.168.1.147:50049/',
  // API: 'http://localhost:50049/',
  // API: 'http://server.knowledgelens.com/test_v4.0/',
  // API: '/mzapi/',
  // API: 'http://momentzs-dev.knowledgelens.com/test_v4.0/',
  firebase: {
    apiKey: "AIzaSyBnQGQT3Q7ygh6ln3DOrsqKK0l5wVFbhsY",
    authDomain: "g0166-zs-momentzs-app.firebaseapp.com",
    databaseURL: "https://g0166-zs-momentzs-app-default-rtdb.firebaseio.com",
    projectId: "g0166-zs-momentzs-app",
    storageBucket: "g0166-zs-momentzs-app.appspot.com",
    messagingSenderId: "683344372195",
    appId: "1:683344372195:web:264728b4a95ce7094880d6",
    measurementId: "G-8V40XKPYFN"
  },
  // API: 'http://b0994eacd43d.ngrok.io/',
  production: false
};
